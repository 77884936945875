import axios from 'axios';

export async function fetchSurveyJSON(id:number) {
  try {
    const res = await axios.get(`${import.meta.env.VITE_API}/${id}`);
    return res;
  }catch (error:any) {
    if (error.response.status === 404) {
      document.location.href = '/';
    }
  }
}

export async function submitCompletedSurvey(data:any) {
    const { surveyId } = data;
    const payload = _convertValuesToString(data);
    return await axios.put(`${import.meta.env.VITE_API}/${surveyId}`, payload);
}

export async function submitPartialSurvey(data:any) {
    const { surveyId } = data;
    const payload = _convertValuesToString(data);
    return await axios.put(`${import.meta.env.VITE_API}/${surveyId}`, payload);
}

const _convertValuesToString = (data:any) => {
  for (const key in data) {
    if (data[key]) {
      data[key] = data[key].toString();
    } else if (typeof data[key] === 'boolean') {
      data[key] = data[key].toString();
    } else {
      data[key] = '0';
    }
  }

  return data;
}
