import styled from 'styled-components';

export const Form = styled.div`
  .sd-rating {
    margin: auto;
  }
`;

export const Header = styled.div`
  padding: 2em;
  display: flex;
  background-color: #fff;
  position: relative;
`;

export const Footer = styled.div`
  padding: 3em 2em;
  display: flex;
  background-color: #1B5E7F;
  justify-content: space-between;
  font-family: 'omnes-pro';

  div, a {
    color: #fff;
    text-align: right;
    font-size: 20px;
    text-decoration: none;
  }
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: rgb(44, 93, 125);
  font-family: 'omnes-pro';
  font-weight: 600;

  @media only screen and (max-width: 600px) {
    text-align: center;
    padding: 30px;
  }
`;