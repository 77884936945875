import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { fetchSurveyJSON, submitCompletedSurvey, submitPartialSurvey } from "./surveyAPI"

export interface SurveyState {
  json: any
  surveyId: number | null
  status: "idle" | "loading" | "failed"
}

const initialState: SurveyState = {
  json: null,
  surveyId: null,
  status: "idle"
}

export const fetchAsync = createAsyncThunk(
  "survey/fetch",
  async (id: number) => {
    const response = await fetchSurveyJSON(id)
    return response?.data
  },
);

export const submitSurvey = createAsyncThunk(
    "survey/submit",
    async (data: any) => {
        data.completed ? await submitCompletedSurvey(data) : await submitPartialSurvey(data);
      },
);

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyId: (state, action) => {
        state.surveyId = action.payload
    },
    removeSurvey: (state) => {
        state.json = {}
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.json = action.payload
      })
      .addCase(fetchAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
});

export const { removeSurvey, setSurveyId } = surveySlice.actions;
export const selectSurveyJSON = (state: RootState) => state.survey.json;
export const selectSurveyId = (state: RootState) => state.survey.surveyId;

export default surveySlice.reducer;