import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import { Header, Footer, NotFound } from "./components/Common";

import "./assets/index.css"
import Logo from "./assets/logo-inline-orange.svg"
import FooterLogo from "./assets/logo2-white.svg"

const router = createBrowserRouter([
  {
    path: "/:id",
    element: <App />,
  },
  {
    path: "*",
    element: <NotFound>This survey has already been submitted or no longer exists.</NotFound>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
        <div className="App">
          <Header>
            <img src={Logo} width="200" />
          </Header>
          
            <RouterProvider router={router} />
          <Footer>
              <img src={FooterLogo} width="100" />
              <div>
                <div>0330 678 0850</div>
                <div><a href="mailto:contact@ascenti.co.uk">contact@ascenti.co.uk</a></div>
              </div>
          </Footer>
        </div> 
    </Provider>
  </React.StrictMode>
)
