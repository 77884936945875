import * as React from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import '../assets/defaultV2.min.css';
import { useAppSelector, useAppDispatch } from "../app/hooks"
import { submitSurvey } from "../features/survey/surveySlice"

export interface SurveyProps {
    data: any;
    currentPageNo: number;
}

export interface QuestionProps {
    json: any;
}

const Questions = (props: QuestionProps) => {
    const dispatch = useAppDispatch()
    const surveyId = useAppSelector(state => state.survey.surveyId);
    const surveyJSON = props.json || null;
    const surveyDOM = React.useRef(null)

    const survey = new Model(surveyJSON);
    const storageItemKey = `ascenti-survey_${surveyId}`;
    survey.sendResultOnPageNext = true;

    survey.onValueChanged.add(function (survey, options) {
        if (options.question.getType() !== 'rating') return;
        // @ts-ignore
        const input = document.querySelector(`[aria-controls="${options.question.propertyHash.id}i_list"] input`);
        if (!input) return;
        // @ts-ignore
        input.placeholder = options.question.value;
    });
    
    survey.onFocusInQuestion.add(function(survey, options){
        
        /*
            This is a hack to fix mobile dropdowns with correct min/max labels
            The survey library doesn't support this out of the box
            We need to wait for the dropdowns to be rendered and then update the labels
        */
        if (options.question.getType() !== 'rating') return;
        const jsonObj = options.question.jsonObj;
        const minLabel = jsonObj.minRateDescription;
        const maxLabel = jsonObj.maxRateDescription;

        
        setTimeout(() => {
            // @ts-ignore
            const list = document.querySelector(`#${options.question.propertyHash.id}i_list`);
            const node = list?.children;

            if (!node) return;
            const listValues = [].slice.call(node);
            const firstOption = listValues[0];
            // @ts-ignore
            const firstOptionValue = firstOption.querySelector('.sd-list__item-body .sv-string-viewer');

            const lastOption = listValues[listValues.length - 1];
            // @ts-ignore
            const lastOptionValue = lastOption.querySelector('.sd-list__item-body .sv-string-viewer');
            firstOptionValue.innerHTML = `0 - ${minLabel}`;
            lastOptionValue.innerHTML = `${listValues.length - 1} - ${maxLabel}`;
        }, 400);

    });
        
    // save session data ---
    function saveSurveyData (survey:SurveyProps) {
        const data = survey.data;
        data.pageNo = survey.currentPageNo;
        window.localStorage.setItem(storageItemKey, JSON.stringify(data));
    }

    survey.onPartialSend.add((survey) => {
        const payload = { ...survey.data, completed: false, surveyId: surveyId };
        saveSurveyData(survey);
        dispatch(submitSurvey(payload));
    });

    survey.onComplete.add((survey) => {
        const payload = { ...survey.data, completed: true, surveyId: surveyId };
        saveSurveyData(survey);
        dispatch(submitSurvey(payload));
    });

    // restore old session data ---
    const prevData = window.localStorage.getItem(storageItemKey) || null;
    if (prevData) {
        const data = JSON.parse(prevData);
        survey.data = data;
        
        if (data.pageNo) {
            survey.currentPageNo = data.pageNo;
        }
    }

    return <Survey model={survey} ref={surveyDOM} />;
}

export default Questions;