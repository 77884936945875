import * as React from "react";
import Questions from "./components/Survey";
import { useParams } from 'react-router-dom';
import Preloader from "./components/Preloader";
import { useAppSelector, useAppDispatch } from "./app/hooks"
import { Form } from "./components/Common";
import { fetchAsync, setSurveyId } from "./features/survey/surveySlice"

function App() {
  const [loading, setLoading] = React.useState(true);
  const surveyJson = useAppSelector(state => state.survey.json);
  const dispatch = useAppDispatch()
  let { id } = useParams(); 

  React.useEffect(() => {
    dispatch(setSurveyId(Number(id)));
    dispatch(fetchAsync(Number(id)));
  }, []);

  React.useEffect(() => {
    if (surveyJson) setLoading(false);
  },[surveyJson]);

  return (
      <Form>
        {loading ? <Preloader /> : <Questions json={surveyJson} /> }
      </Form>
  );
}

export default App

